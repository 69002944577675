import React from 'react'
import './footer.css';
import { Menu } from '../Menu/Menu';
import { faInstagram,faYoutube,faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import inditech from '../../assets/Core/logo.png'



  
const Footer = () => {
  return (
    
      <div className="footer-main">
          {/* <div className='footer-header-container'>
            <hr className='footer-hr' />
            <h1 className='footer-head-large'>Are You Actually Seeing What You Think You Are Seeing?</h1>
          </div> */}

          <div className="footer-main-container">
            <hr className='footer-hr' />
            <div className='footer-core-container'>
              <div className='footer-navbar'>
                <Menu className='footer-menu-gap'/>
              </div>
            </div>
            
          </div>

          <div className="footer-copyright">
            
            <div className='footer-bottom-text'>
              {/* <a href='/privacy'><h3 className='footer-copyright-para'>All Rights Reserved By Thinkathon</h3></a>| */}
              <Link to="/devs"><h3 className='footer-copyright-para'>Developed By <img className='footer-inditech-logo' src={inditech} alt=''/></h3></Link>
            </div>
            <div className='footer-signin-logo-container'>
              <Link to="/signin"><FontAwesomeIcon className= "logos footersignin" icon={faEnvelope} /></Link>
            </div>
            
          </div>
      </div>
    
    
  )
}

export default Footer
