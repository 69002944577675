import { Link } from "react-router-dom";
import React from "react";
import "./Menu.css";

export const Menu = ({ className }) => {
  return (
    <div className={`menu-main ${className}`}>
      <Link to="/home">
        <p className="menu-home-text menu-txt">Home</p>
      </Link>
      <Link to="/projects">
        <p className="menu-members-text menu-txt">Projects</p>
      </Link>
      {/* <Link to="/achievements">
        <p className="menu-events-text menu-txt">Achievements</p>
      </Link> */}
      {/* <Link to="/certificates"><p className='menu-members-text menu-txt'>Certificates</p></Link> */}
      {/* <Link to="/experiences"><p className='menu-members-text menu-txt'>Experience</p></Link> */}

      <Link to="/contactme">
        <p className="menu-members-text menu-txt">Contact Me</p>
      </Link>
    </div>
  );
};
