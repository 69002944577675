import axios from "axios";
// const BASE_URL = 'http://192.168.137.150:3500';
// const BASE_URL = "http://192.168.1.25:3500";
const BASE_URL = "https://portfolio-karan-backend.onrender.com";
// const BASE_URL = 'https://thinkathon-website-backend.onrender.com';

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
