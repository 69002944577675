import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./About.css";
import karan from "../../assets/Core/Karan-Avatar.jpg";
import marker from "../../assets/Core/icons8-verified-100.png";
import axios from "../../api/axios";

const DATA_URL = "/data";

const About = () => {
  const [projects, setProjects] = useState([]);
  const [skills, setSkills] = useState([]);
  const [category, setCategory] = useState("Websites");

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(`${DATA_URL}/projects`);
      setProjects(response.data);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSkillsData = async () => {
    try {
      const response = await axios.get(`${DATA_URL}/skills`);
      setSkills(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProjectData();
    fetchSkillsData();
  }, []);

  return (
    <div className="about-main">
      <div className="about-core">
        <div className="about-box">
          <div className="about-box-heading">About Me</div>
          <div className="about-desc-container">
            <img className="about-hero-img" src={karan} alt="" />
            <h2 id="aboutme">
              <h2>Welcome to my portfolio website!</h2>
              <br />I am a dedicated and enthusiastic technology aficionado
              currently pursuing <br />
              <strong>
                Bachelor of Technology in Artificial Intelligence and Machine
                Learning at Chandigarh Group of Colleges, Jhanjeri.
              </strong>{" "}
              <br />
              <br />I am Passionate about the transformative power of
              technology, I have founded Inditech, where we are pioneering
              advanced AI-based facial recognition ERP systems and automated
              event management solutions, bringing innovative concepts closer to
              commercialization. Proficient in full-stack development with
              skills in MongoDB, React.js, Express.js, and Node.js, I am
              committed to expanding my expertise in AI and ML technologies like
              PyTorch and TensorFlow, while also actively engaging in leadership
              roles and extracurricular activities to drive forward my vision
              for the future.
            </h2>
          </div>
        </div>
        <div className="about-box" id="skills">
          {/* <h1>Skills</h1> */}
          <div className="about-skills-container">
            <div className="about-skill-container">
              <span className="about-hero-span" id="skill-container">
                <h1 id="skill-title">FRONTEND DEVELOPMENT</h1>
                <div className="about-skill-list-container">
                  <div className="about-skill-list">
                    {skills
                      .filter((item) => item.type === "Frontend")
                      .map((item, index) => (
                        <div className="about-skill-list-item">
                          <img
                            className="about-skill-marker"
                            src={marker}
                            alt=""
                          />
                          <div className="about-skill-content">
                            <p>{item.name}</p>
                            <p id="skill-desc">{item.experience}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </span>
            </div>
            <div className="about-skill-container">
              <span className="about-hero-span" id="skill-container">
                <h1 id="skill-title">BACKEND DEVELOPMENT</h1>
                <div className="about-skill-list-container">
                  <div className="about-skill-list">
                    {skills
                      .filter((item) => item.type === "Backend")
                      .map((item, index) => (
                        <div className="about-skill-list-item">
                          <img
                            className="about-skill-marker"
                            src={marker}
                            alt=""
                          />
                          <div className="about-skill-content">
                            <p>{item.name}</p>
                            <p id="skill-desc">{item.experience}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="about-box" id="projects">
          <div className="about-box-heading">
            <h1>Projects</h1>
            <div className="about-box-project-category">
              {[...new Set(projects.map((event) => event.type))] // Extract unique types
                .filter((type) => type !== "Miscellaneous") // Exclude "Miscellaneous"
                .map((type, index) => (
                  <p
                    key={index}
                    className={
                      type === category ? "about-box-cat-selected" : ""
                    }
                    onClick={() => {
                      setCategory(type);
                    }}
                  >
                    {type}
                  </p> // Render each type
                ))}
            </div>
          </div>
          <div className="about-projects-container">
            <div className="about-projects-cards-row">
              {projects
                .filter((item) => item.type === category)
                .slice(0, 3)
                .map((card, index) => (
                  <div
                    className="events-vertical-card"
                    key={index}
                    id="home-projects-card"
                  >
                    <div className="events-vertical-card-bottom">
                      <h1 className="events-vertical-name-text">{card.name}</h1>
                      <p className="events-vertical-name-desc">
                        {card.headline}
                      </p>
                    </div>
                    <div className="events-buttons">
                      <Link
                        to={`/projects/${card.name
                          .toLowerCase()
                          .replace(/\s+/g, "")}`}
                      >
                        <button className="events-button" type="button">
                          Learn More
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            <Link to="/projects">
              <button class="animated-button">
                <svg
                  viewBox="0 0 24 24"
                  class="arr-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                </svg>
                <span class="text">More Projects</span>
                <span class="circle"></span>
                <svg
                  viewBox="0 0 24 24"
                  class="arr-1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
