export const timelineData = [
  {
      date: "5/5/2025",
      heading: "Problem Statement Registration",
      description: `Applicationsssss.`,
  },
  {
      date: "7/5/2025",
      heading: "Problem Statement Registration",
      description: `Applicationsasdadas.`,
  },
  {
      date: "8/5/2025",
      heading: "Problem Statement Registration",
      description: `Applicationstgfh.`,
  },
]