import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { Gallery } from "../Gallery/Gallery";
import "./Event-Page.css";
import "react-multi-carousel/lib/styles.css";

const EventOverview = ({ overview }) =>
  overview ? (
    <div className="events-page-info">
      <h1 className="events-vertical-name-text">Overview</h1>
      <p>{overview}</p>
    </div>
  ) : null;

const EventTechnologies = ({ tech }) =>
  tech ? (
    <div className="events-page-info" id="bottom-box">
      <h1 className="events-vertical-name-text">Technologies Used</h1>
      <ul>
        {tech.map((item, index) => (
          <li className="events-page-features-text" key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  ) : null;

const EventFeatures = ({ features }) =>
  features ? (
    <div className="events-page-info" id="bottom-box">
      <h1 className="events-vertical-name-text">Features</h1>
      <ul>
        {features.map((item, index) => (
          <li className="events-page-features-text" key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  ) : null;

const EventLinks = ({ link, github, type }) =>
  link || github ? (
    <div className="events-buttons">
      {link && (
        <Link to={link}>
          <button className="events-button" type="button">
            See Live
          </button>
        </Link>
      )}
      {type !== "Websites" && github && (
        <Link to={github}>
          <FontAwesomeIcon
            className="events-github-button"
            type="button"
            icon={faGithub}
          />
        </Link>
      )}
    </div>
  ) : null;

const EventPage = ({ data }) => {
  document.title = `Projects || ${data.name}`;
  return (
    <div className="events-page-main">
      <div className="events-page-cards-row">
        <div className="events-page-horizontal-card">
          <div className="events-page-vertical-card-bottom">
            <h1 className="events-vertical-name-text">{data.name}</h1>
            <p className="events-page-headline-text">{data.headline}</p>
          </div>
          <EventLinks link={data.link} github={data.github} type={data.type} />
        </div>
      </div>
      <EventOverview overview={data.desc?.overview} />
      <div className="events-page-bottom-row">
        <EventTechnologies tech={data.desc?.tech} />
        <EventFeatures features={data.desc?.features} />
      </div>
      {data.media.length !== 0 && <Gallery />}
    </div>
  );
};

const ProjectDetail = () => {
  const [projects, setProjects] = useState([]);
  const { projectName } = useParams();

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axios.get(`/data/projects`);
        setProjects(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchProjectData();
  }, []);

  const project = projects.find(
    (p) =>
      p.name.toLowerCase().replace(/\s+/g, "") ===
      projectName.toLowerCase().replace(/\s+/g, "")
  );

  if (!project) {
    return <div>Project not found</div>;
  }

  return <EventPage data={project} />;
};

export default ProjectDetail;
